


















import { defineComponent, PropType } from "@vue/composition-api";
import { Button, Cell, Dialog } from "vant";
import moment from "moment";
moment.locale("zh-cn");
import axios from "@/helpers/axios";

interface Share {
  shareId: number;
  name: string;
  isSiteLimited: boolean;
  siteCount: number;
  isBusinessLimited: boolean;
  businessCount: number;
  toUserCount: number;
  endTime: number;
}

export default defineComponent({
  components: {
    [Cell.name]: Cell,
    [Button.name]: Button
  },
  props: {
    share: {
      type: Object as PropType<Share>
    },
    showEdit: {
      type: Boolean,
      defuault() {
        return false;
      }
    }
  },
  methods: {
    formatTime(time: number | Date) {
      return moment(time).format("lll");
    },
    async revokeShare(shareId: number) {
      const confirm = await Dialog.confirm({
        message: "您确定要撤销吗?"
      }).then(() => true);
      if (!confirm) return;
      const res = await axios
        .post("/api/manage/account/revokeShare", { shareId: shareId })
        .then(() => true)
        .catch(() => false);
      if (res) {
        this.$emit("change");
      }
    },
    async resumeShare(shareId: number) {
      const confirm = await Dialog.confirm({
        message: "您确定要恢复吗?"
      }).then(() => true);
      if (!confirm) return;
      const res = await axios
        .post("/api/manage/account/resumeShare", { shareId: shareId })
        .then(() => true)
        .catch(() => false);
      if (res) {
        this.$emit("change");
      }
    }
  }
});
