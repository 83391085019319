























import Vue from "vue";
import { Image, Skeleton } from "vant";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

interface Data {
  title: string;
  total: {
    value: number;
    unitPrefix: string;
  };
  list: [
    {
      value: number;
      unit: string;
      label: string;
      image?: string;
    }
  ];
}

@Component({
  components: {
    [Image.name]: Image,
    [Skeleton.name]: Skeleton
  }
})
export default class AccountTotal extends Vue {
  loading = true;
  @Prop() data?: Data;
  // data = {
  //   title: "1234",
  //   total: {
  //     value: 22000,
  //     unitPrefix: "¥"
  //   },
  //   list: [
  //     { value: 120, unit: "元", label: "收入", image: "/images/stat/icon-stat-income.png" },
  //     { value: 12022, unit: "元", label: "笔数", image: "/images/stat/icon-stat-refund.png" },
  //     { value: 130, unit: "元", label: "退款", image: "/images/stat/icon-stat-other.png" }
  //   ]
  // };
  mounted() {
    this.loading = false;
  }
}
