





























import { Button, Cell, CellGroup, Checkbox, CheckboxGroup, Picker, Popup, Search, Toast } from "vant";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

interface Business {
  id: number;
  name: string;
}

@Component({
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Search.name]: Search,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Button.name]: Button
  }
})
export default class BusinessPicker extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop({ default: false }) mutiple!: boolean;
  @Prop({ default: 0 }) defaultId!: number;
  @Prop() businessList!: Business[];

  fullList = [] as Business[];
  list = [] as Business[];
  loading = true;
  keyword = "";
  defaultIndex = 0;

  checked = [] as Business[];
  currentPicked!: Business;

  get show() {
    return this.value;
  }
  set show(val) {
    this.$emit("input", val);
  }

  @Watch("show")
  onShow(show: boolean) {
    if (show && this.list.length === 0) {
      this.loadData();
    }
  }
  @Watch("businessList")
  onBusinessListChange() {
    this.loadData();
  }
  loadData() {
    if (this.businessList) {
      const list = this.businessList;
      if (!this.mutiple && this.businessList.length > 0 && this.businessList[0].id != 0) {
        list.unshift({ id: 0, name: "全部运营商" });
      }
      this.fullList = list;
      this.list = list;
      return;
    }
    this.loading = true;
    const requestData = {
      keyword: this.keyword
    };
    this.$axios
      .post("/api/manage/getBusinessListForPick", requestData)
      .then(res => {
        this.list = res.data.data;
        this.fullList = res.data.data;
        if (this.defaultId != 0) {
          this.defaultIndex = this.list.findIndex(v => {
            return this.defaultId == v.id;
          });
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  searchData() {
    this.list = this.fullList.filter(v => v.name.indexOf(this.keyword) !== -1);
  }
  onPick() {
    let data;
    if (this.mutiple) {
      data = this.checked.map(v => {
        return {
          id: v.id,
          name: v.name
        };
      });
    } else {
      data = {
        id: this.currentPicked.id,
        name: this.currentPicked.name
      };
    }
    this.$emit("onPick", data);
    this.show = false;
  }
  @Watch("list") // as the onChange method on picker not actived when list change
  onListChange(list: Business[]) {
    this.changeCurrentPicked(list[0]);
  }
  onChange(sth: never, value: Business) {
    Toast(value.name);
    this.changeCurrentPicked(value);
  }
  changeCurrentPicked(value: Business) {
    this.currentPicked = value;
  }
  toggleCheckbox(index: number) {
    const checkboxes = this.$refs.checkboxes as Checkbox[];
    checkboxes[index].toggle();
  }
}
