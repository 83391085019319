








































































































































































import { Button, Cell, Image, Calendar, CellGroup, Popup, Picker, Icon, Popover, Tabs, Tab, Field, Dialog, DatetimePicker, Toast, Notify } from "vant";
import Total from "./components/total.vue";
import Share from "./components/share.vue";
import MyShareItem from "./components/my-share-item.vue";
import SitePicker from "@/components/picker/site-picker.vue";
import BusinessPicker from "@/components/picker/business-picker.vue";
import { defineComponent } from "@vue/composition-api";
import moment from "moment";
moment.locale("zh-cn");
import axios from "@/helpers/axios";

export default defineComponent({
  components: {
    [Button.name]: Button,
    [Image.name]: Image,
    [Cell.name]: Cell,
    [Calendar.name]: Calendar,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Icon.name]: Icon,
    [Popover.name]: Popover,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
    [DatetimePicker.name]: DatetimePicker,
    [Notify.Component.name]: Notify.Component,
    SitePicker,
    BusinessPicker,
    Total,
    Share,
    MyShareItem
  },
  data() {
    return {
      total: {} as Record<string, number>,
      list: [],
      siteList: [],
      siteMap: new Map(),
      businessList: [],
      userName: "",
      userPhone: "",
      shareId: (this.$route.query.shareId || 0) as number,

      today: moment()
        .startOf("day")
        .valueOf(),
      minDate: moment()
        .startOf("day")
        .subtract(12, "month")
        .toDate(),
      maxDate: moment()
        .startOf("day")
        .toDate(),
      showCalendar: false,

      startTime: moment()
        .startOf("day")
        .valueOf(),
      endTime: moment()
        .startOf("day")
        .valueOf(),

      siteIds: "",
      siteIdsName: "全部站点",
      exportSiteIds: "",
      exportSiteIdsName: "全部站点",
      showSitePicker: false,

      businessId: 0,
      businessName: "全部运营商",
      exportBusinessId: 0,
      exportBusinessName: "全部运营商",
      showBusinessPicker: false,

      showDeviceCategoryPicker: false,
      deviceCategorys: [
        { value: -1, label: "全部设备" },
        { value: 0, label: "充电设备" },
        { value: 1, label: "换电设备" }
      ],
      deviceCategory: { value: -1, label: "全部设备" },
      showDianbaoIntro: localStorage.platformId == 3,
      showExportDialog: false,
      exportEmail: "",
      exportMinDate: moment("2021-03-01").toDate(),
      exportMaxDate: moment()
        .startOf("day")
        .toDate(),
      showExportCalendar: false,
      exportStartTime: moment()
        .startOf("day")
        .valueOf(),
      exportEndTime: moment()
        .startOf("day")
        .valueOf(),

      showShareList: false,
      shareToList: [] as { shareId: number; endTime: number; isSiteLimited: boolean; siteList: { id: number; siteName: string }[]; toUserId: number; toUserInfo: string }[],
      shareFromList: [] as { shareId: number; endTime: number; isSiteLimited: boolean; siteList: { id: number; siteName: string }[]; fromUserId: number; fromUserInfo: string }[],
      showShareManage: false,
      showCheckOtherBtn: false,
      showCheckOtherDialog: false,
      otherPhone: ""
    };
  },
  created() {
    this.getData();
    if (this.shareId == 0) {
      this.loadShareList();
    }
  },
  computed: {
    totalData(): null | {
      total: { value: number; unitPrefix: string };
      list: { value: number; unit: string; label: string; image: string }[];
    } {
      if (this.$isEmptyObject(this.total)) return null;
      return {
        total: {
          value: Math.round((this.total.incomeAmount + this.total.otherAmount - this.total.refundAmount) * 100) / 100,
          unitPrefix: "¥"
        },
        list: [
          { value: this.total.incomeAmount, unit: "元", label: "收入", image: "/images/stat/icon-stat-income.png" },
          { value: this.total.refundAmount, unit: "元", label: "退款", image: "/images/stat/icon-stat-refund.png" },
          { value: this.total.otherAmount, unit: "元", label: "其他", image: "/images/stat/icon-stat-other.png" }
        ]
      };
    },
    isToday(): boolean {
      return this.startTime == this.today && this.endTime == this.today;
    },
    isYesterday(): boolean {
      const yesterday = this.today - 86400 * 1000;
      return this.startTime == yesterday && this.endTime == yesterday;
    },
    isLastMonth(): boolean {
      const start = moment()
        .startOf("month")
        .subtract(1, "month")
        .valueOf();
      const end = moment()
        .startOf("month")
        .subtract(1, "day")
        .valueOf();
      return this.startTime == start && this.endTime == end;
    },
    isThisMonth(): boolean {
      const start = moment()
        .startOf("month")
        .valueOf();
      return this.startTime == start && this.endTime == this.today;
    },
    isLastHalfYear(): boolean {
      const start = moment()
        .startOf("day")
        .subtract(12, "month")
        .valueOf();
      return this.startTime == start && this.endTime == this.today;
    },
    timeFilterMsg(): string {
      let str = "";
      if (this.startTime == this.endTime) {
        str = moment(this.startTime).format("YY-MM-DD");
      } else {
        str = moment(this.startTime).format("YY-MM-DD") + " 至 " + moment(this.endTime).format("YY-MM-DD");
      }
      if (this.isToday) {
        str += " 今天";
      }
      if (this.isYesterday) {
        str += " 昨天";
      }
      if (this.isThisMonth) {
        str += " 本月";
      }
      if (this.isLastMonth) {
        str += " 上月";
      }
      if (this.isLastHalfYear) {
        str += " 一年";
      }
      return str;
    },
    exportTimeMsg(): string {
      let str = "";
      if (this.exportStartTime == this.exportEndTime) {
        str = moment(this.exportStartTime).format("MM-DD");
      } else {
        str = moment(this.exportStartTime).format("MM-DD") + " 至 " + moment(this.exportEndTime).format("MM-DD");
      }
      return str;
    }
  },
  watch: {
    startTime: "onTimeChange",
    endTime: "onTimeChange"
  },
  methods: {
    onTimeChange() {
      this.getData();
    },
    getData() {
      const postData = {
        shareId: this.shareId,
        startTime: this.startTime,
        endTime: this.endTime,
        siteIds: this.siteIds,
        businessId: this.businessId,
        deviceCategory: this.deviceCategory && this.deviceCategory.value >= 0 ? this.deviceCategory.value : undefined
      };
      axios.post("/api/manage/account/getAccountStat", postData).then(res => {
        const data = res.data.data;
        this.userName = data.userName;
        this.userPhone = data.userPhone;
        this.total = data.total;
        if (this.siteIds.length === 0) {
          this.siteList = data.siteList;
          this.siteList.forEach((v: { id: number; name: string }) => {
            this.siteMap.set(v.id, v.name);
          });
          this.businessList = data.businessList;
        }
        this.list = data.list.map((v: { siteId: number; siteName: string }) => {
          v.siteName = this.siteMap.get(v.siteId);
          return v;
        });
      });
    },
    toSiteStat(item: { siteId: number; siteName: string }) {
      let url = "site-stat?shareId=" + this.shareId + "&siteId=" + item.siteId + "&startTime=" + this.startTime + "&endTime=" + this.endTime + "&timeMsg=" + encodeURIComponent(this.timeFilterMsg) + "&siteName=" + encodeURIComponent(item.siteName);
      if (this.deviceCategory) {
        url += "&deviceCategory=" + this.deviceCategory.value;
      }
      this.$router.push(url);
    },
    // ui
    onPickDeviceCategory(deviceCategory: { value: number; label: string }) {
      this.deviceCategory = deviceCategory;
      this.showDeviceCategoryPicker = false;
      this.getData();
    },
    onPickSite(list: { id: number; name: string }[]) {
      const idList = [];
      for (const item of list) {
        idList.push(item.id);
      }
      const siteIdsName = "已选择" + idList.length + "个站点";
      const siteIds = idList.join(",");
      if (this.showExportDialog) {
        this.exportSiteIds = siteIds;
        this.exportSiteIdsName = siteIdsName;
        return;
      }
      this.siteIdsName = siteIdsName;
      this.siteIds = siteIds;
      this.getData();
    },
    onPickBusiness(business: { id: number; name: string }) {
      const businessId = business.id;
      const businessName = business.name;
      if (this.showExportDialog) {
        this.exportBusinessId = businessId;
        this.exportBusinessName = businessName;
        return;
      }
      this.businessId = businessId;
      this.businessName = businessName;
      this.getData();
    },
    onFilterTime(date: [Date, Date]) {
      const [start, end] = date;
      this.startTime = start.valueOf();
      this.endTime = end.valueOf();
      this.showCalendar = false;
    },
    filterToday() {
      this.startTime = this.today;
      this.endTime = this.today;
    },
    filterYesterday() {
      const yesterday = this.today - 86400 * 1000;
      this.startTime = yesterday;
      this.endTime = yesterday;
    },
    filterThisMonth() {
      const start = moment()
        .startOf("month")
        .valueOf();
      this.startTime = start;
      this.endTime = this.today;
    },
    filterLastMonth() {
      const start = moment()
        .startOf("month")
        .subtract(1, "month")
        .valueOf();
      const end = moment()
        .startOf("month")
        .subtract(1, "day")
        .valueOf();
      this.startTime = start;
      this.endTime = end;
    },
    filterLastHalfYear() {
      const start = moment()
        .startOf("day")
        .subtract(12, "month")
        .valueOf();
      this.startTime = start;
      this.endTime = this.today;
    },

    // export
    async exportAccountRecord() {
      if (this.exportEmail.indexOf("@") === -1) {
        Toast.fail("请输入正确的邮箱");
        return;
      }
      const postData = {
        shareId: this.shareId,
        startTime: this.exportStartTime,
        endTime: this.exportEndTime + 86400 * 1000,
        siteIds: this.exportSiteIds,
        businessId: this.exportBusinessId,
        email: this.exportEmail
      };
      const res = await axios.post("/api/manage/account/exportAccountRecord", postData).then(() => true);
      if (res) {
        Toast.success("发送成功");
      }
    },
    onChangeExportTime(date: [Date, Date]) {
      const [start, end] = date;
      this.exportStartTime = start.valueOf();
      this.exportEndTime = end.valueOf();
      this.showExportCalendar = false;
    },

    // user share
    async loadShareList() {
      const data = await axios
        .post("/api/manage/account/getShareList", { type: 1 })
        .then(res => res.data.data)
        .catch(() => {
          return { shareToList: [], shareFromList: [] };
        });
      this.shareToList = data.shareToList;
      this.shareFromList = data.shareFromList;
      this.showCheckOtherBtn = data.showCheckOtherBtn;
    },
    formatTime(time: number | Date) {
      return moment(time).format("lll");
    },
    useShare(shareId: number) {
      // this.showShareList = false;
      // this.shareId = shareId;
      this.$toMiniAppWeb("/web/manager/v/account/stat?shareId=" + shareId);
    }
  }
});
