







































































import { defineComponent, PropType } from "@vue/composition-api";
import { Button, Cell, CellGroup, DatetimePicker, Field, Icon, NavBar, Popup, Radio, RadioGroup, Switch, Toast } from "vant";
import SitePicker from "@/components/picker/site-picker.vue";
import BusinessPicker from "@/components/picker/business-picker.vue";
import UserPicker from "@/components/picker/user-picker.vue";
import MyShareItem from "./my-share-item.vue";
import moment from "moment";
moment.locale("zh-cn");
import axios from "@/helpers/axios";

interface Site {
  id: number;
  siteName: string;
}

interface Business {
  id: number;
  name: string;
}

interface User {
  id: number;
  info: string;
}

interface Share {
  shareId: number;
  name: string;
  isSiteLimited: boolean;
  siteCount: number;
  isBusinessLimited: boolean;
  businessCount: number;
  toUserCount: number;
  endTime: number;
  toUserList: User[];
  siteList: Site[];
  businessList: Business[];
}

export default defineComponent({
  name: "share",
  props: {
    siteList: {
      type: Array as PropType<Site[]>
    },
    businessList: {
      type: Array as PropType<Business[]>
    }
  },
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Switch.name]: Switch,
    SitePicker,
    BusinessPicker,
    UserPicker,
    MyShareItem
  },
  data() {
    return {
      list: [] as Share[],
      showShareAdd: false,
      shareAdd: {
        shareId: 0,
        name: "",
        hasEndTime: false,
        endTime: moment()
          .add(1, "day")
          .toDate(),
        endTimeMsg: moment()
          .add(1, "day")
          .format("lll"),
        toUserList: [] as User[],
        siteList: [] as Site[],
        businessList: [] as Business[]
      },
      showDatePicker: false,
      showUserPicker: false,
      filter: "none",
      showSitePicker: false,
      showBusinessPicker: false
    };
  },
  created() {
    this.loadMyShareList();
  },
  methods: {
    async loadMyShareList() {
      const res = await axios
        .post("/api/manage/account/getMyShareList")
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!res) return;
      this.list = res.list;
    },
    setShareAddDate(d: Date) {
      this.shareAdd.endTime = d;
      this.shareAdd.endTimeMsg = moment(d).format("lll");
      this.showDatePicker = false;
    },
    onPickShareSite(list: Site[]) {
      this.shareAdd.siteList = this.shareAdd.siteList.concat(list);
    },
    onPickShareBusiness(list: Business[]) {
      this.shareAdd.businessList = this.shareAdd.businessList.concat(list);
    },
    onPickShareUser(user: { phone: string; userName: string; userId: number; isAuth: boolean }) {
      this.shareAdd.toUserList.push({
        id: user.userId,
        info: user.isAuth ? user.userName + " - " + user.phone : "未实名" + " - " + user.phone
      });
    },
    goAddShare() {
      this.shareAdd = {
        shareId: 0,
        name: "",
        hasEndTime: false,
        endTime: moment()
          .add(1, "day")
          .toDate(),
        endTimeMsg: moment()
          .add(1, "day")
          .format("lll"),
        toUserList: [] as User[],
        siteList: [] as Site[],
        businessList: [] as Business[]
      };
      this.filter = "none";
      this.showShareAdd = true;
    },
    editShare(share: Share) {
      this.shareAdd = Object.assign({}, share, {
        hasEndTime: share.endTime > 0,
        endTime: moment(share.endTime).toDate(),
        endTimeMsg: moment(share.endTime).format("lll")
      });
      this.filter = share.isBusinessLimited ? "business" : share.isSiteLimited ? "site" : "none";
      this.showShareAdd = true;
    },
    async saveShare() {
      if (!this.shareAdd.name) {
        Toast.fail("请填写分享名称");
        return;
      }
      const reqData = {
        shareId: this.shareAdd.shareId,
        name: this.shareAdd.name,
        endTime: this.shareAdd.hasEndTime ? this.shareAdd.endTime.getTime() : 0,
        toUserIds: this.shareAdd.toUserList
          .reduce((ids, user) => {
            ids.push(user.id);
            return ids;
          }, [] as number[])
          .join(","),
        siteIds:
          this.filter == "site"
            ? this.shareAdd.siteList
                .reduce((ids, site) => {
                  ids.push(site.id);
                  return ids;
                }, [] as number[])
                .join(",")
            : "",
        businessIds:
          this.filter == "business"
            ? this.shareAdd.businessList
                .reduce((ids, business) => {
                  ids.push(business.id);
                  return ids;
                }, [] as number[])
                .join(",")
            : ""
      };
      const res = await axios
        .post("/api/manage/account/saveShare", reqData)
        .then(() => true)
        .catch(() => false);
      if (res) {
        this.loadMyShareList();
        this.showShareAdd = false;
      }
    },
    formatTime(time: number | Date) {
      return moment(time).format("lll");
    }
  }
});
