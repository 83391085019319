






import { defineComponent } from "@vue/composition-api";
import { Dialog, Field } from "vant";
import axios from "@/helpers/axios";

export default defineComponent({
  name: "UserPicker",
  props: {
    value: Boolean
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field
  },
  data() {
    return {
      phone: ""
    };
  },
  computed: {
    show: {
      get(): boolean {
        return this.value || false;
      },
      set(show: boolean) {
        this.$emit("input", show);
      }
    }
  },
  methods: {
    async searchUser() {
      const data: UserInfo = await axios
        .post("/api/manage/getUser1", { phone: this.phone })
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!data) return;
      this.$emit("onPick", data);
    }
  }
});

interface UserInfo {
  phone: string;
  userName: string;
  userId: number;
  isAuth: boolean;
}
